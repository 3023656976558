import { UALJs } from "ual-plainjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import { Wombat } from 'wombat-ual'
// import { User } from 'universal-authenticator-library';

// // login functio
// var ual = null
/*global UAL:writable,loggedInUser:writable*/
/*eslint no-undef: "error"*/
export default class Wallet {
    constructor() {
        this.chain = {
            chainId: process.env.VUE_APP_CHAIN_ID,
            rpcEndpoints: [{
                protocol: "https",
                host: process.env.VUE_APP_CHAIN_URL,
                port: '443',
            }, ],
        };
        this.appName = process.env.VUE_APP_NAME;
        this.wax = new Wax([this.chain], { appName: this.appName });
        this.anchor = new Anchor([this.chain], { appName: this.appName });
        this.wombat = new Wombat([this.chain], { appName: this.appName })
        this.sessionAuthenticator;
        UAL = new UALJs(this.loginCallBack, [this.chain], this.appName, [this.wax, this.anchor, this.wombat]);
        // validate authentication
        setTimeout(async () => {
            await this.sessionLogin();
        }, 2000);
    }
    loginCallBack(users) {
        loggedInUser = users[0];
        console.log(users);
        if (loggedInUser.session) {
            localStorage.setItem("session-authenticator", "Anchor")
        } else if (loggedInUser.scatter) {
            localStorage.setItem("session-authenticator", "Wombat")
        } else {
            localStorage.setItem("session-authenticator", "Wax")
        }
        localStorage.setItem('wax_user', loggedInUser.accountName)
        localStorage.setItem(UALJs.SESSION_ACCOUNT_NAME_KEY, loggedInUser.accountName)
    }
    getSession() {
        // validate authentication
        const auth = UAL.getAuthenticators()
        UAL.attemptasync(auth.availableAuthenticators);
        return UAL
    }
    async getLoginUser() {
        const activeUser = localStorage.getItem("wax_user");
        if (UAL) {
            if (localStorage.getItem("session-authenticator") == "Wax") {
                UAL.loginUser(this.wax, activeUser)
            } else if (localStorage.getItem("session-authenticator") == "Wombat") {
                UAL.loginUser(this.wombat, activeUser)
            } else {
                UAL.loginUser(this.anchor, activeUser)
            }
            return UAL
        }
        return UAL
    }
    async sessionLogin() {
        const authenticators = UAL.getAuthenticators();
        const authenticatorName = localStorage.getItem("session-authenticator");
        const sessionAuthenticator = authenticators.availableAuthenticators.find((authenticator) => authenticator.getName() == authenticatorName);
        const accountName = localStorage.getItem("wax_user") || undefined;
        await UAL.loginUser(sessionAuthenticator, accountName);
        return loggedInUser = sessionAuthenticator.users[0];
    }
    async sessionAuth() {
        return UAL;
    }
}