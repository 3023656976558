<template>
  <div class="main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2 p-0">
          <Sidebar></Sidebar>
        </div>
        <div class="col-lg-10 mt-lg-5 mt-md-5 mt-3">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="searchForSection mb-4 mb-lg-0">
                <div class="row">
                  <div class="col-lg-8 col-12">
                    <div class="d-flex searchSectionInner">
                      <div>
                        <img
                          src="images/lost-tear.png"
                          alt="lost-tear"
                          class="img-fluid"
                        />
                      </div>
                      <div>
                        <h2>The Search for the Lost Tear</h2>
                      </div>
                    </div>
                    <div class="searchPara">
                      <p>
                        Players will embark on a journey to the Eye of the
                        Ocean, a vast expanse of water that is said to hold the
                        first of the lost tears. They will have to navigate
                        treacherous currents and avoid dangerous sea creatures
                        in order to find the first tear.
                      </p>
                      <p>
                        Additional Journey information like, weather events that
                        could occur or beasts that are rumored to be in the
                        area.
                      </p>
                    </div>
                    <div class="rewardSection">
                      <h3 class="text-uppercase theme_color float-start mt-4">
                        Reward
                      </h3>
                      <ul class="list-group flex-row float-lg-end float-md-end">
                        <li class="list-group reward_frame">
                          <img
                            src="images/NOLA-coin.png"
                            alt="NOLA-coin"
                            class="img-fluid"
                          />
                        </li>
                        <li class="list-group reward_frame">
                          <img
                            src="images/nola-coin-1.png"
                            alt="NOLA-coin"
                            class="img-fluid"
                          />
                        </li>
                        <li class="list-group reward_frame">
                          <img
                            src="images/upgrade-icon.png"
                            alt="NOLA-coin"
                            class="img-fluid"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <ul class="reward_info mb-0">
                      <li class="theme_color fw-bold text-uppercase">
                        Type
                        <p class="text-white">Name Type</p>
                      </li>
                      <li class="theme_color fw-bold text-uppercase">
                        AREA
                        <p class="text-white">NAME AREA</p>
                      </li>
                      <li class="theme_color fw-bold text-uppercase">
                        TRIBE
                        <p class="text-white">NAME TRIBE</p>
                      </li>
                      <li class="theme_color fw-bold text-uppercase">
                        DISTANCE
                        <p class="text-white">
                          123 <span class="text-lowercase">ml</span>
                        </p>
                      </li>
                      <li class="theme_color fw-bold text-uppercase">
                        LEVEL
                        <p class="text-white">1</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="ship_section position-relative">
                <p class="text-white card_heading">SHIP</p>
                <a href="#" class="card_menu">
                  <i
                    class="fa fa-bars"
                    aria-hidden="true"
                    data-v-41458b80=""
                    style="color: rgb(230, 230, 255)"
                  ></i>
                </a>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="left_card">
                      <img
                        src="/images/Nebula_Longboat.png"
                        alt="Nebula_Longboat"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6">
                    <div
                      class="
                        d-lg-flex d-md-flex d-inline-block
                        flex-wrap
                        justify-content-end
                        mobile_grid
                      "
                    >
                      <div class="cards_main">
                        <img
                          src="images/energy-potion.png"
                          alt="energy-potion"
                          class="img-fluid"
                        />
                      </div>
                      <div class="cards_main">
                        <img
                          src="images/energy-potion.png"
                          alt="energy-potion"
                          class="img-fluid"
                        />
                      </div>
                      <div class="cards_main">
                        <img
                          src="images/energy-potion.png"
                          alt="energy-potion"
                          class="img-fluid"
                        />
                      </div>
                      <div class="cards_main">
                        <img
                          src="images/energy-potion.png"
                          alt="energy-potion"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="
                        d-lg-flex d-md-flex d-inline-block
                        justify-content-between
                        typesMobile_design
                        pb-4 pb-lg-0 pb-md-0
                      "
                    >
                      <div class="fw-bold text-uppercase">
                        <p class="theme_color mb-0 mt-2">HEALTH</p>
                        <p class="text-white mb-0">100%</p>
                      </div>
                      <div class="fw-bold text-uppercase">
                        <p class="theme_color mb-0 mt-2">Energy</p>
                        <p class="text-white mb-0">80</p>
                      </div>
                      <div class="fw-bold text-uppercase">
                        <p class="theme_color mb-0 mt-2">Attack</p>
                        <p class="text-white mb-0">100</p>
                      </div>
                      <div class="fw-bold text-uppercase">
                        <p class="theme_color mb-0 mt-2">Defense</p>
                        <p class="text-white mb-0">100</p>
                      </div>
                      <div class="fw-bold text-uppercase">
                        <p class="theme_color mb-0 mt-2">Weight</p>
                        <p class="text-white mb-0">100</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="
                  d-lg-flex d-md-flex d-inline-block
                  justify-content-between
                  mt-2
                "
              >
                <div class="cardFrame_main position-relative">
                  <div class="frameText">
                    <p class="text-white">CREATURE</p>
                    <a href="#">
                      <i
                        class="fa fa-bars"
                        aria-hidden="true"
                        data-v-41458b80=""
                        style="color: rgb(230, 230, 255)"
                      ></i>
                    </a>
                  </div>
                  <div class="card_innerImg">
                    <img
                      src="images/Time_Twister.png"
                      alt="Time_Twister"
                      class="img-fluid cardmainImg"
                    />
                  </div>
                  <div class="card_info_bottom">
                    <span class="card_points">
                      <img
                        src="images/HIT-POINT.png"
                        alt="HIT-POINT.png"
                        class="img-fluid"
                      />
                      100
                    </span>
                    <span class="card_power">
                      <img
                        src="images/POWER.png"
                        alt="POWER.png"
                        class="img-fluid"
                      />
                      3
                    </span>
                  </div>
                </div>
                <div class="cardFrame_main position-relative">
                  <div class="frameText">
                    <p class="text-white">CREATURE</p>
                    <a href="#">
                      <i
                        class="fa fa-bars"
                        aria-hidden="true"
                        data-v-41458b80=""
                        style="color: rgb(230, 230, 255)"
                      ></i>
                    </a>
                  </div>
                  <div class="card_innerImg">
                    <img
                      src="images/Time_Twister.png"
                      alt="Time_Twister"
                      class="img-fluid cardmainImg"
                    />
                  </div>
                  <div class="card_info_bottom">
                    <span class="card_points">
                      <img
                        src="images/HIT-POINT.png"
                        alt="HIT-POINT.png"
                        class="img-fluid"
                      />
                      100
                    </span>
                    <span class="card_power">
                      <img
                        src="images/POWER.png"
                        alt="POWER.png"
                        class="img-fluid"
                      />
                      3
                    </span>
                  </div>
                </div>
                <div class="cardFrame_main position-relative landScapeFrame">
                  <div class="frameText">
                    <p class="text-white">CREATURE</p>
                    <a href="#">
                      <i
                        class="fa fa-bars"
                        aria-hidden="true"
                        data-v-41458b80=""
                        style="color: rgb(230, 230, 255)"
                      ></i>
                    </a>
                  </div>
                  <div class="card_innerImg">
                    <img
                      src="images/owl.png"
                      alt="owl"
                      class="img-fluid cardmainImg"
                    />
                  </div>
                  <div class="card_info_bottom">
                    <span class="card_points">
                      <img
                        src="images/HIT-POINT.png"
                        alt="HIT-POINT.png"
                        class="img-fluid"
                      />
                      100
                    </span>
                    <span class="card_power">
                      <img
                        src="images/POWER.png"
                        alt="POWER.png"
                        class="img-fluid"
                      />
                      3
                    </span>
                  </div>
                </div>
                <div class="cardFrame_main position-relative">
                  <div class="frameText">
                    <p class="text-white">CREATURE</p>
                    <a href="#">
                      <i
                        class="fa fa-bars"
                        aria-hidden="true"
                        data-v-41458b80=""
                        style="color: rgb(230, 230, 255)"
                      ></i>
                    </a>
                  </div>
                  <div class="card_innerImg">
                    <img
                      src="images/Time_Twister.png"
                      alt="Time_Twister"
                      class="img-fluid cardmainImg"
                    />
                  </div>
                  <div class="card_info_bottom">
                    <span class="card_points">
                      <img
                        src="images/HIT-POINT.png"
                        alt="HIT-POINT.png"
                        class="img-fluid"
                      />
                      100
                    </span>
                    <span class="card_power">
                      <img
                        src="images/POWER.png"
                        alt="POWER.png"
                        class="img-fluid"
                      />
                      3
                    </span>
                  </div>
                </div>
                <div class="cardFrame_main position-relative">
                  <div class="frameText">
                    <p class="text-white">CREATURE</p>
                    <a href="#">
                      <i
                        class="fa fa-bars"
                        aria-hidden="true"
                        data-v-41458b80=""
                        style="color: rgb(230, 230, 255)"
                      ></i>
                    </a>
                  </div>
                  <div class="card_innerImg">
                    <img
                      src="images/Time_Twister.png"
                      alt="Time_Twister"
                      class="img-fluid cardmainImg"
                    />
                  </div>
                  <div class="card_info_bottom">
                    <span class="card_points">
                      <img
                        src="images/HIT-POINT.png"
                        alt="HIT-POINT.png"
                        class="img-fluid"
                      />
                      100
                    </span>
                    <span class="card_power">
                      <img
                        src="images/POWER.png"
                        alt="POWER.png"
                        class="img-fluid"
                      />
                      3
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <footer class="footer_main">
                <div class="row">
                  <div class="col-2">
                    <div class="single_btn position-relative">
                      <img
                        src="/images/back-btn.png"
                        alt="back-btn.png"
                        class="img-fluid"
                      />
                      <a href="#" class="footer_btnText theme_color">HEAL</a>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="multiple_btn nav_btn position-relative">
                      <img
                        src="/images/footer-bar.png"
                        alt="footer-bar.png"
                        class="img-fluid w-100"
                      />
                      <ul>
                        <li>
                          <a href="#" class="footer_btnText theme_color">
                            Health</a
                          >
                        </li>
                        <li>
                          <a href="#" class="footer_btnText theme_color">
                            Energy</a
                          >
                        </li>
                        <li>
                          <a href="#" class="footer_btnText theme_color">
                            Attack</a
                          >
                        </li>
                        <li>
                          <a href="#" class="footer_btnText theme_color">
                            Defence</a
                          >
                        </li>
                        <li>
                          <a href="#" class="footer_btnText theme_color">
                            Weight</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="single_btn position-relative">
                      <img
                        src="/images/back-btn.png"
                        alt="back-btn.png"
                        class="img-fluid"
                      />
                      <a href="#" class="footer_btnText theme_color">BEGIN</a>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar.vue";
export default {
  name: "Journey",
  components: {
    Sidebar,
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.theme_color {
  color: #0cf1ef !important;
}
.footer_btnText:hover {
  background-image: url("/images/begin-btn.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-position: 100% -10px;
}
/* .main .col-2 {
  width: 18.666667%;
} */
.searchForSection {
  padding: 35px 35px 28px 35px;
  background-image: url("/images/box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.searchSectionInner img {
  width: 70%;
}
.searchSectionInner h2 {
  color: #00ffba;
  font-family: "Montserrat", sans-serif;
}
.searchPara p {
  color: #ffffff;
}
.reward_frame img {
  width: 80px;
  padding: 20px;
}
.reward_frame {
  background-image: url("/images/reward-box.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.reward_info {
  background-image: url("/images/box-3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  list-style: none;
  height: 100%;
  padding: 24px 30px;
}
.reward_info p {
  margin-bottom: 10px;
}
.ship_section {
  padding: 35px 35px 25px 35px;
  background-image: url("/images/ship-frame.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.left_card img {
  height: 275px;
  margin-top: 20px;
}
.cards_main {
  background-image: url("/images/ship-small-box-frame.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  list-style: none;
  padding: 10px 15px;
  width: 35%;
  display: inline-block;
  margin: 25px 30px 0 0;
}
.card_heading {
  position: absolute;
  top: 20px;
  left: 35px;
  font-weight: bold;
}
.card_menu {
  position: absolute;
  top: 20px;
  right: 35px;
  font-weight: bold;
}
.cardFrame_main {
  background-image: url("/images/card-frame-2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 35px 10px;
}
.cardFrame_main .cardmainImg {
  width: 200px;
  padding: 10px;
}
.landScapeFrame {
  width: 30%;
  background-image: url("/images/card-frame.png");
}
.landScapeFrame .cardmainImg {
  width: 80%;
}
.landScapeFrame .card_points {
  left: 165px;
}
.landScapeFrame .card_power {
  right: 174px;
}
.card_innerImg {
  text-align: center;
}
.frameText p {
  position: absolute;
  top: 16px;
  font-weight: bold;
  left: 30px;
}
.frameText a {
  position: absolute;
  top: 15px;
  right: 35px;
}
.card_points {
  position: absolute;
  bottom: 17px;
  left: 54px;
  color: #ffffff;
}
.card_points img {
  width: 15px;
}
.card_power {
  position: absolute;
  bottom: 17px;
  right: 70px;
  color: #ffffff;
}
.card_power img {
  width: 15px;
}
.footer_main {
  margin-top: 40px;
  background-image: url("/images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* padding: 20px 10px; */
}
.footer_btnText {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 26px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.nav_btn {
  top: -35px;
}
.single_btn {
  top: -23px;
}
.multiple_btn ul li a {
  font-size: 26px;
  font-weight: bold;
  width: 190px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 0px;
  display: inline-block;
}
.multiple_btn ul {
  margin: 0px;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 80px;
}
.multiple_btn ul li {
  list-style: none;
  float: left;
  width: 20%;
  text-align: center;
  position: relative;
  left: -20px;
  top: -70px;
  height: 60px;
}
</style>