<template>
  <nav class="navbar navbar-expand-lg h-100">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="showMenu = !showMenu"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        fill="#ffffff"
        class="bi bi-list"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </button>
    <div
      class="sidebar_main list-group position-relative collapse navbar-collapse"
      :class="showMenu ? 'show' : ''"
    >
      <ul class="sidebar_menuInner">
        <li class="list-group">
          <a href="#" class="theme_color text-uppercase fw-bold"
            >Dragon's Breath</a
          >
        </li>
        <li class="list-group">
          <a href="/journey" class="theme_color text-uppercase fw-bold"
            >ACTIVE JOURNEYS</a
          >
        </li>
        <li class="list-group">
          <a href="#" class="theme_color text-uppercase fw-bold">WORLD MAP</a>
        </li>
      </ul>
      <ul class="sidebar_menuInner">
        <li class="list-group">
          <a href="/journeyselection" class="theme_color text-uppercase fw-bold">JOURNEYS</a>
        </li>
        <li class="list-group">
          <a href="#" class="theme_color text-uppercase fw-bold"
            >MY COLLECTION</a
          >
        </li>
        <li class="list-group">
          <a href="#" class="theme_color text-uppercase fw-bold">STAKING</a>
        </li>
        <li class="list-group">
          <a href="#" class="theme_color text-uppercase fw-bold">SHOP</a>
        </li>
      </ul>
      <div class="info_icon">
        <a href="#">
          <img src="images/info-icon.png" alt="info-icon" class="img-fluid" />
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style>
.sidebar_main {
  background-image: url("/images/sidebar-frame.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 17px 0px !important;
}
.sidebar_menuInner {
  background-image: url("/images/menu-board.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* height: 46%; */
  padding: 45px 25px;
  text-align: center;
  width: 90%;
  margin: 16px auto;
}
.sidebar_menuInner li a {
  padding: 15px 16px;
  background-image: url("/images/menu-button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 25px;
  font-size: 18px;
  /* transition: all ease-in-out 0.3s; */
}
.sidebar_menuInner li a:hover {
  background-image: url("/images/hover-button.png");
}
.sidebar_menuInner li:last-child a {
  margin-bottom: 0px;
}
/* .main .col-2 {
  width: 18.666667%;
} */
.info_icon {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.info_icon img {
  width: 40px;
}
</style>